exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-message-overview-tsx": () => import("./../../../src/pages/message/overview.tsx" /* webpackChunkName: "component---src-pages-message-overview-tsx" */),
  "component---src-pages-student-overview-tsx": () => import("./../../../src/pages/student/overview.tsx" /* webpackChunkName: "component---src-pages-student-overview-tsx" */),
  "component---src-pages-user-overview-tsx": () => import("./../../../src/pages/user/overview.tsx" /* webpackChunkName: "component---src-pages-user-overview-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */),
  "component---src-pages-visit-replacement-tsx": () => import("./../../../src/pages/visit-replacement.tsx" /* webpackChunkName: "component---src-pages-visit-replacement-tsx" */),
  "component---src-pages-visit-tsx": () => import("./../../../src/pages/visit.tsx" /* webpackChunkName: "component---src-pages-visit-tsx" */)
}

