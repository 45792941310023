/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { asDynamicObservableObject } from 'mobx/dist/internal';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class VisitStore {

  loading: boolean = false;

  loadingVisitTable: boolean = false;

  visitListByOrg: any = { totalCount: 0, data: [] };

  visitListInDateRange: any = { totalCount: 0, data: [] };

  visitChangeList: any = [];

  visitOverlapList: any = [];

  organisationList: any = {totalCount: 0, data: []};

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingVisitTable: observable,
      visitChangeList: observable,
      visitListInDateRange: observable,
      organisationList: observable,
      visitOverlapList: observable,
      setVisitTableLoading: action,
      updateChangeRequestStatus: action,
      getOrganisationListbyGrouId: action,
      checkTimeAvailability: action,
    });

    this.rootStore = rootStore;
  }

  setVisitTableLoading = (flag: boolean) => {
    this.loadingVisitTable = flag;
  }

  /* ========== TEACHER CRUD ========== */
  getVisitListByOrgId = async (id: number, take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Visit/GetVisitListByOrgId/${id}/${take}/${skip}`);
      this.visitListByOrg = res.data;
      console.log("Visit List by Org", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }



  getOrgVisitListByDateRange = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Visit/GetOrgVisitListByDateRange`, reqModel);
  
      this.visitListInDateRange = res.data;
      console.log("Visit List in Date Range", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  // change status request

  getChangeRequestListByVisitId = async (id: number) => {
    this.loadingVisitTable = true;
    try {
      const res = await Axios.get(`/api/Visit/GetChangeRequestListByVisitId/${id}`, {headers: {ContentType: "application/json"}})
      console.log("Visit Change List", res.data);
      this.visitChangeList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisitTable = false;
    }
  }

  updateChangeRequestStatus = async(reqId: number, status: boolean) => {
    this.loadingVisitTable = true;
    try {
      const res = await Axios.put(`/api/Visit/updateChangeRequestStatus/${reqId}/${status}`)
      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisitTable = false;
    }
  }



  // create change

  createVisitChangeRequest = async(changeModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post('/api/Visit/CreateVisitChangeRequest' , changeModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  // get organisation list by group id

  getOrganisationListbyGrouId = async(id: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Visit/GetOrganisationListByGroupId/${id}`);
      this.organisationList.totalCount = res.data.length
      this.organisationList.data = res.data
      return Promise.resolve(res.headers)
    } catch(err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  checkTimeAvailability = async(checkTime: any) => {
    try {
      const checkTimeToList = [checkTime];
      const res = await Axios.post(`/api/Visit/CheckTimeAvailability`, checkTimeToList, {headers: {"Content-Type": "application/json"}});
      this.visitOverlapList = res.data;
      console.log(res.data);
      return Promise.resolve(res.data);
    } catch(err) {
      return Promise.reject(err);
    } finally {

    }
  }
}

