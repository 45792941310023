import { FileCategory, FileType } from "./options";

export const isProduction = process.env.NODE_ENV === 'production';

const prodBackEndUrl = 'https://employerportapi.auokka.com'; // 'https://mp.tour61.com.au'
const prodFrontEndUrl = 'iems.vmortech.com.au'; // 'http://www.tourx.com.au'
const prodPictureBedUrl = 'https://dashapi.avemaria.vic.edu.au/Image?id='; // 'http://images.tour61.com.au'


export const backEndBaseURL = isProduction ? prodBackEndUrl : 'https://employerportapi.auokka.com'//'http://localhost:52012'
export const frontEndBaseUrl = isProduction ? prodFrontEndUrl : 'http://localhost:3000';
export const imageURLPrefix = isProduction ? prodPictureBedUrl : 'http://1dd5-149-167-146-139.ngrok.io/api/file?';
export const fileEndBaseUrl = 'https://schooladminapi.auokka.com/';

export const  constructImgUrl = (type: FileType, category: FileCategory, id: number, name: string) => {
    return `https://schooladminapi.auokka.com/api/file/getFile/${type}/${category}/${id}/${name}`;
}

export const encryptedPassword = "*ENCRYPTEDPASSWORD*";