/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import { composeImageUrlsInCartItems, ImageSize } from '../utilities/image-url-composer';
import { isNative, isWeb } from '../utilities/platform';
import atob from '../utilities/atob';
import RootStore from './root-store';
import { GetOrgId } from '../constants/options';

export default class UserStore {
  get isAuthed() {
    if (typeof window !== "undefined" && localStorage.USER_TOKEN && localStorage.USER_INFO)
      return true;
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  set isAuthed(value) {
    console.log('Deleting token...');
    if (!value && isWeb) {
      delete localStorage.USER_TOKEN;
      delete localStorage.USER_INFO;
      delete localStorage.GROUPID;
    }
  }

  userToken: string | null = null;

  loading: boolean = false;

  msgList: any = { totalCount: 0, data: [] };


  //june
  userProfile: any = { totalCount: 0, data: [] };

  msgNumber: number = 0;

  todayVisitData: any = { visitOrgNumber: 0, visitStudentNumber: 0, visitTeacherNumber: 0 };

  yearlyVisitData: any = {
    currentYearData: { visitOrgNumber: 0, visitStudentNumber: 0, visitTeacherNumber: 0 },
    lastYearData: { visitOrgNumber: 0, visitStudentNumber: 0, visitTeacherNumber: 0 }
  };

  userProfileList: any = { totalCount: 0, data: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isAuthed: computed,
      userToken: observable,
      loading: observable,
      msgList: observable,
      msgNumber: observable,
      userProfileList: observable,
      //june
      userProfile: observable,

      signIn: action,
      signOut: action,
      getMessageList: action,
      getNewMessageNumber: action,
      readMessage: action,
      getOrganisationUserProfileList: action,
      updateOrganisationUserProfile: action,
      deleteOrganisationUserProfile: action,
      putOrganisationUser: action,
    });

    this.rootStore = rootStore;
  }

  signIn = async (userModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/user/SignIn`, userModel);
      const token = `Bearer ${res.data.token}`;
      console.log(res.data);
      this.userToken = res.data.token;
      localStorage.USER_TOKEN = token;
      let userInfo = {
        id: res.data.id,
        title: res.data.title,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        organisationId: res.data.organisationId,
      }
      localStorage.USER_INFO = JSON.stringify(userInfo);
      localStorage.CALENDAR = JSON.stringify({
        semester: res.data.currentSemesterId,
        startDate: res.data.startDate,
        endDate: res.data.endDate,
      });
      // group id
      localStorage.GROUPID = res.data.groupId;
      return Promise.resolve(res.data);
    } catch (err) {
      this.isAuthed = false;
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  signOut = () => {
    this.isAuthed = false;
    this.userToken = "";
    window.location.href = "/";
  };

  getMessageList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Message/GetMessageList/${take}/${skip}`);
      this.msgList = res.data;
      console.log("MSG List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  // =========== USER CRUD ===========

  getOrganisationUserProfileList = async (take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/User/GetOrganisationUserProfileList/${GetOrgId()}/${take}/${skip}`);
      this.userProfileList = res.data;
      console.log("User Profile List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  updateOrganisationUserProfile = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/User/UpdateOrganisationUserProfile`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  deleteOrganisationUserProfile = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/User/deleteOrganisationUserProfile/${id}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  // =========== END USER CRUD ===========
  getNewMessageNumber = async () => {
    try {
      const res = await Axios.get(`/api/Message/GetNewMessageNumber`);
      this.msgNumber = res.data;
      console.log("MSG Number", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  readMessage = async (id: number) => {
    try {
      const res = await Axios.get(`/api/Message/ReadMessage/${id}`);
      console.log("Read", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  // sign up
  putOrganisationUser = async (signUpForm: any) => {
    try {
      const res = await Axios.put(`/api/User/UpdateOrganisationUserProfile`, signUpForm);
      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err);
    } finally {

    }
  }
}
