/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class StudentStore {

  loading: boolean = false;

  loadingVisit: boolean = false;

  loadingTrainee : boolean = false;

  loadingVisitTable: boolean = false;

  // Student Profile List
  studentProfile: any = { totalCount: 0, data: [] };

  // Just One Student Profile by Org ID
  oneStudentProfile: any = {};

  traineeshipVisitList: any = { totalCount: 0, data: [] };

  visitHistoryList: any = { totalCount: 0, data: [] };

  visitCurrentList: any = { totalCount: 0, data: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingTrainee: observable,
      loadingVisitTable: observable,
      studentProfile: observable,
      oneStudentProfile: observable,
      traineeshipVisitList: observable,
      visitHistoryList: observable,
      visitCurrentList: observable,
      getStudentProfileListByOrgId: action,
      getStudentProfileById: action,
      getVisitListByStudentId: action,
      getVisitListByTraineeshipId: action,

    });

    this.rootStore = rootStore;
  }


  // student List
  getStudentProfileListByOrgId = async (orgId: number, take: number, skip: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Student/GetStudentProfileListByOrgId/${orgId}/${take}/${skip}`);
      console.log("Student Profile", res.data);
      this.studentProfile = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  // Get one student 
  getStudentProfileById = async (studentId: number, orgId: number) => {
    this.loadingTrainee = true;
    try {
      const res = await Axios.get(`/api/Student/GetStudentProfileById/${studentId}/${orgId}`);
      this.oneStudentProfile = res.data;
      console.log('get student profile by id: ', toJS(this.oneStudentProfile))
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingTrainee = false;
    }
  }

  /**
   * @deprecated do not use this function, use getVisitListByTraineeshipId
   */
  getVisitListByStudentId = async (studentId: number, isHistory: boolean, take: number, skip: number) => {
    this.loadingVisit = true;
    try {
      const res = await Axios.get(`/api/Student/GetVisitListByStudentId/${studentId}/${isHistory}/${take}/${skip}`);
      if (isHistory) this.visitHistoryList = res.data;
      else this.visitCurrentList = res.data;
      console.log("Visit List By Student ", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingVisit = false;
    }
  }

  // get visit list by traineeship id
  getVisitListByTraineeshipId = async (traineeshipId: number) => {
    this.loadingVisit = true;
    try {
      const res = await Axios.get(`/api/Visit/GetVisitListByTraineeshipId/${traineeshipId}`);
      this.traineeshipVisitList.totalCount = res.data.length
      this.traineeshipVisitList.data= res.data
      console.log("visit list by traineeship Id: ", res.data)
      return Promise.resolve(res.data)
    } catch(err) {
      return Promise.reject(err)
    } finally {
      this.loadingVisit = false;
    }
  }


}
